/*
original idea taken from http://jsfiddle.net/mn4rr/1/

then ran through this to jquery it: https://decaffeinate-project.org/repl/

note that the 'decaffeinate' initially turned the script into es6 which doesn't work with 'gulp-uglify' so i've run it through https://babeljs.io/en/repl
which converts it to es5. nice.
*/
var fitText = {
	init: function init() {
		fitText.autoSizeText();

		site.win.resize(function () {
			fitText.autoSizeText();
		});
	},
	autoSizeText: function () {
		var elements = $('.fit-text');
		if (elements.length < 0) {
			return;
		}

		return Array.from(elements).map(function (el) {
			return function (el) {

				var resizeText = function () {
					var elNewFontSize = parseInt($(el).css('font-size').slice(0, -2)) - 1 + 'px';
					return $(el).css('font-size', elNewFontSize);
				};

				return function () {
					var result = [];
					while (el.scrollWidth > el.offsetWidth) {
						result.push(resizeText());
					}
					return result;
				}();
			}(el);
		});
	}
};
$(function () {
	fitText.init();
	site.doc.ajaxComplete(function () {
		fitText.init();
	});
});