var timeline = {
	init: function () {
    $('section.timeline div.accordion:not(.js-timeline-collapse-done)').each(function () {
      var $accordion = $(this);
      var $collapses = $accordion.find('.collapse');
      var $titles = $accordion.find('.item-title');
      $titles.each(function () {
        var $title = $(this);
        $title.click(function (e) {
          e.preventDefault();
          var $collapse = $($title.attr('href'));
          $collapses.not($collapse).slideUp('slow');
          $collapse.slideToggle('slow', function () {
            if (!$collapse.is(":visible")) {
              return;
            }

            site.animate($title);
          });
        });
      });
      $collapses.first().slideToggle('slow');
      $accordion.addClass('js-timeline-collapse-done');
    });
	}
};
$(function () {
	timeline.init();
	site.doc.ajaxComplete(function () {
		timeline.init();
	});
});