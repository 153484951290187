var serviceOpeningHours = {
    init: function () {
        $('div.service-group:not(.js-service-group-done)').each(function () {
            var $serviceGroup = $(this);
            var $expandAll = $serviceGroup.find('.expand-all').first();
            var $collapseAll = $serviceGroup.find('.collapse-all').first();
            var $services = $serviceGroup.find('.service');
            var $collapses = $services.children('.collapse');
            var $titles = $services.find('.title');

            var toggleExpandAll = function (expandAll) {
                if (!expandAll) {
                    $expandAll.addClass('active');
                    $collapseAll.removeClass('active');
                } else {
                    $expandAll.removeClass('active');
                    $collapseAll.addClass('active');
                }
            };

            $titles.each(function () {
                var $title = $(this);
                $title.on('click', function (e) {
                    e.preventDefault();
                    $titles.removeClass('card-title-expanded');
                    var $collapse = $title.closest('.service').children('.collapse').first();
                    $collapses.not($collapse).slideUp('slow');
                    $collapse.slideToggle('slow', function () {
                        if (!$collapse.is(":visible")) {
                            return;
                        }

                        $title.addClass('card-title-expanded');
                        site.animate($title);
                    });

                    if (!$expandAll.hasClass('active')) {
                        toggleExpandAll();
                    }
                });
            });

            toggleExpandAll();

            $expandAll.on('click', function (e) {
                e.preventDefault();
                $collapses
                    .slideDown('slow')
                    .promise()
                    .then(function () {
                        $titles.addClass('card-title-expanded');
                        site.animate($expandAll);

                        toggleExpandAll(true);
                    });
            });

            $collapseAll.on('click', function (e) {
                e.preventDefault();
                $collapses
                    .slideUp('slow')
                    .promise()
                    .then(function () {
                        $titles.removeClass('card-title-expanded');

                        toggleExpandAll();
                    });
            });

            $serviceGroup.addClass('js-service-group-done');
        });
    }
};

$(function () {
    serviceOpeningHours.init();
    site.doc.ajaxComplete(function () {
        serviceOpeningHours.init();
    });
});