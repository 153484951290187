var loading = {
	preInit: function () {
		var loadingSpinner = '<div data="jakes" id="loadingSpinner" class="loading" style="display: none;"><div class="loading-spinner"><div class="fa-3x"><i class="fas fa-circle-notch fa-spin"></i></div></div><div class="loading-fade"></div></div>';
		$('body').append(loadingSpinner);
	},

	spinner: function (hide) {
		var loadingSpinner = $('#loadingSpinner');

		if (hide) {
			loadingSpinner.hide();
		}
		if (!hide) {
			loadingSpinner.show();
		}
	}
};
$(function () {
	loading.preInit();
});