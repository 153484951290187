var videos = {
	init: function () {
		var videos = $('video');
		videos.not('.js-videos-done').each(function () {
			var $this = $(this);
			$this.off('play').on('play', function () {
				var id = this.id;
				videos.each(function () {
					if (id !== this.id) {
						this.pause();
					}
				});
			});
			$this.addClass('js-videos-done');
		});
	}
};
$(function () {
	videos.init();
	site.doc.ajaxComplete(function () {
		videos.init();
	});
});