var myChart = {
	init: function () {
		var userAgent = navigator.userAgent.toLowerCase();
		var isiDevice = /ipad|iphone|ipod/i.test(userAgent);
		var isAndroid = /android/i.test(userAgent);

		if (!isiDevice && !isAndroid)
			return;

		var intentLink = "epicmychart://orgselect/orgID=918";
		var iTunesLink = "itms-apps://itunes.apple.com/us/app/mychart/id382952264?mt=8";
		var googlePlayLink = "http://play.google.com/store/apps/details?id=epic.mychart.android";

		$('.my-chart-link').on('click', function (e) {
			e.preventDefault();

			window.location.href = intentLink;
			var appLink = !isAndroid ? iTunesLink : googlePlayLink;

			setTimeout(function () {
				window.location.href = appLink;
			}, 25);
		});
	}
};
$(function () {
//	myChart.init();
	site.doc.ajaxComplete(function () {
	//	myChart.init();
	});
});