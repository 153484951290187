var carousels = {
  init: function () {
    $('section.carousel div.carousel:not(.js-carousel-done)').each(function() {
      var $this = $(this);
      //this lets mobile users swipe the slides (check out 'jquery.bcSwipe.min.js' in the plugins folder)
      $this.bcSwipe({ threshold: 50 });
      $this.addClass('js-carousel-done');
    });
  }
};
$(function () {
  carousels.init();
  site.doc.ajaxComplete(function () {
    carousels.init();
  });
});