var quiz = {
	gender: null,
	bmiHeight: null,
	bmiWeight: null,
	bmiScore: null,
	bmiRanges: null,
	isSmlUp: false,
	allButtonGroups: null,
	init: function () {
		$('section.quiz:not(.js-quiz-done)').each(function () {
			var $section = $(this);

			var pageName = $section.attr('data-page-name');
			var $questionCounter = $('#questionCounter');
			var $questionNumber = $('#questionNumber');
			var $questionCount = $('#questionCount');

			var $questionGroups = $section.find('div.question-group');
			var $validationRows = $questionGroups.find('div.validation-row');
			var $allButtonGroups = $questionGroups.find('div.btn-group-toggle');

			quiz.allButtonGroups = $allButtonGroups;

			$questionGroups.each(function () {
				var $questionGroup = $(this);

				var $validationRow = $questionGroup.find('div.validation-row');
				var $previousButton = $questionGroup.find('button.btn-previous');
				var $nextButton = $questionGroup.find('button.btn-next');
				var $buttonGroups = $questionGroup.find('div.btn-group-toggle');
				var $bmiGroup = $questionGroup.find('div.bmi-group');

				if ($bmiGroup.length > 0) {
					quiz.bmiScore = $questionGroup.find('.bmi-score');
					quiz.bmiRanges = $questionGroup.find('.bmi-range');
					var $rangeInputs = $bmiGroup.find('input[type=range]');
					$rangeInputs.each(function() {
						var $this = $(this);
						if ($this.attr('name') === 'Height') {
							quiz.bmiHeight = $this;
						} else if ($this.attr('name') === 'Weight') {
							quiz.bmiWeight = $this;
						}
						var $sliderValue = $this.closest('div.form-group').find('span.slider-value');
						$this.on('input change', function (e) {
							var value = e.target.value;
							$sliderValue.text(value);
							quiz.calculateBmi();
						});
					});
					quiz.calculateBmi();
				}

				if ($previousButton.length > 0) {
					$previousButton.on('click', function () {
						site.animate($section);
						$validationRows.removeClass('d-flex');
						$questionGroup.removeClass('d-block');
						$questionGroup.addClass('d-none');

						var $previousRow = $questionGroup.prev("div.question-group");
						if (quiz.gender === 'Male' && $previousRow.attr('data-skip-if-gender-is-male') === 'true') {
							$previousRow = $previousRow.prev("div.question-group");
						}

						$previousRow.removeClass('d-none');
						$previousRow.addClass('d-block');

						site.analytics.trackEvent('Quiz > ' + pageName, 'Previous > Q' + $questionNumber.text(), void 0);

						var questionNumber = parseInt($questionNumber.text(), 10) - 1;
						if (questionNumber === 1) {
							$questionCounter.addClass('d-none');
							$questionCount.addClass('d-none');
						} else {
							$questionCount.removeClass('d-none');
						}

						$questionCount.text(quiz.gender === 'Male' ? parseInt($questionGroups.not('[data-skip-if-gender-is-male="true"]').length, 10) : parseInt($questionGroups.length, 10));
						$questionNumber.text(questionNumber);
					});
				}

				if ($nextButton.length > 0) {
					$nextButton.on('click', function () {

						var proceed = false;

						$validationRows.removeClass('d-flex');
						$validationRows.addClass('d-none');

						var $buttonGroup = $buttonGroups.length === 1 ? $buttonGroups : $buttonGroups.filter('.d-inline-flex');

						var checkedRadioButton = $('input[name=' + $buttonGroup.attr('data-group-name') + ']:checked');

						if ($questionGroup.attr('data-dont-validate') === 'true') {
							proceed = true;
						} else if (checkedRadioButton.length === 0) {
							$validationRow.removeClass('d-none');
							$validationRow.addClass('d-flex');
						} else {
							proceed = true;
							if ($buttonGroup.attr('data-is-gender-question') === 'true') {
								quiz.gender = parseInt(checkedRadioButton.val(), 10) === 6 ? 'Male' : 'Female';

								//now we have the gender we can show/hide the required groups
								$allButtonGroups.each(function () {
									var $this = $(this);
									if ($this.attr('data-requires-gender') === 'true') {
										if ($this.attr('data-group-name') === quiz.gender) {
											$this.removeClass('d-none');
											$this.addClass('d-inline-flex');
										} else {
											$this.removeClass('d-inline-flex');
											$this.addClass('d-none');
										}
									}
								});
							}
						}

						if (proceed === true) {
							$validationRows.removeClass('d-flex');

							var questionsAndAnswersParams = [];

							$section.find('input[type=radio]:checked').each(function () {
								var $this = $(this);
								questionsAndAnswersParams.push(encodeURI('q' + $this.attr('data-question-number') + '=' + $this.attr('data-text')));
							});

							questionsAndAnswersParams.push('bmi=' + quiz.bmiScore.text());

							$section.find('input[type=range]').each(function () {
								var $this = $(this);
								questionsAndAnswersParams.push(encodeURI('q' + $this.attr('data-question-number') + $this.attr('data-text') + '=' + $this.val()));
							});

							if ($nextButton.attr('data-complete') === 'true') {
								var score = 0;

								var bmi = parseInt(quiz.bmiScore.text(), 10);

								if (quiz.scoreBetween(bmi, 25, 29)) {
									score = 4;
								}

								if (quiz.scoreBetween(bmi, 30, 34)) {
									score = 9;
								}

								if (quiz.scoreBetween(bmi, 35, 999)) {
									score = 14;
								}

								$section.find('input[type=radio]:checked').each(function () {
									var $this = $(this);
									score += parseInt($this.val(), 10);
								});

								var outcomes = JSON.parse($('#outcomes').html());

								var url = '';

								for (var i = 0; outcomes.length > i; i += 1) {
									if (quiz.scoreBetween(score, outcomes[i].LowScore, outcomes[i].HighScore)) {
										url = outcomes[i].Link.Url;
										questionsAndAnswersParams.push('riskLevel' + '=' + encodeURI(outcomes[i].Title));
										questionsAndAnswersParams.push('score' + '=' + score);
										break;
									}
								}

								if (url === '') {
									alert('No matching outcome for score ' + score);
								} else {

									site.analytics.trackEvent('Quiz > ' + pageName, 'Complete', void 0);

									var dataCapturepParams = questionsAndAnswersParams;
									var dataCapture = JSON.parse($('#dataCapture').html());
									dataCapturepParams.push('subject' + '=' + dataCapture.DataCaptureEmailSubjectLine);
									dataCapturepParams.push('email' + '=' + dataCapture.DataCaptureEmailAddress);

									loading.spinner(false);
									$.ajax({
										contentType: 'application/json',
										type: "GET",
										url: "/Umbraco/Api/QuizApi/DataCapture/?" + dataCapturepParams.join('&'),
										success: function (data) {
											window.location.href = url + '?' + questionsAndAnswersParams.join('&');
										},
										error: function () {
											loading.spinner(true);
										}
									});
								}
							} else {
								site.animate($section);
								$questionGroup.removeClass('d-block');
								$questionGroup.addClass('d-none');

								var $nextRow = $questionGroup.next("div.question-group");
								if (quiz.gender === 'Male' && $nextRow.attr('data-skip-if-gender-is-male') === 'true') {
									$nextRow = $nextRow.next("div.question-group");
								}

								$nextRow.removeClass('d-none');
								$nextRow.addClass('d-block');

								var questionNumber = parseInt($questionNumber.text(), 10);

								site.analytics.trackEvent('Quiz > ' + pageName, 'Next > Q' + questionNumber, void 0);

								$questionCounter.removeClass('d-none');
								$questionCount.removeClass('d-none');

								$questionCount.text(quiz.gender === 'Male' ? parseInt($questionGroups.not('[data-skip-if-gender-is-male="true"]').length, 10) : parseInt($questionGroups.length, 10));
								$questionNumber.text(questionNumber + 1);
							}
						}
					});
				}
			});

			$section.addClass('js-quiz-done');
		});

		if (quiz.allButtonGroups !== null) {
			quiz.isSmlUp = site.win.width() >= 576;
			quiz.resize();
			site.win.resize(function () {
				quiz.isSmlUp = site.win.width() >= 576;
				quiz.resize();
			});
		}
	},
	scoreBetween: function(score, low, high) {
		return (score - low) * (score - high) <= 0;
	},
	calculateBmi: function() {
		var heightCalc = parseInt(quiz.bmiHeight.val(), 10) / 100;
		var weightCalc = parseInt(quiz.bmiWeight.val(), 10);
		var bmi = Math.round(weightCalc / (heightCalc * heightCalc));
		quiz.bmiScore.text(bmi);

		quiz.bmiRanges.removeClass('d-inline');
		quiz.bmiRanges.addClass('d-none');

		quiz.bmiRanges.each(function () {
			var $this = $(this);
			var min = parseInt($this.attr('data-min'));
			var max = parseInt($this.attr('data-max'));
			if (bmi >= min && bmi < max) {
				$this.addClass('d-inline');
			}
		});
	},
	resize: function () {
		if (quiz.isSmlUp === true) {
			if (quiz.allButtonGroups.hasClass('btn-group-vertical')) {
				quiz.allButtonGroups.removeClass('btn-group-vertical');
			}
		} else {
			if (!quiz.allButtonGroups.hasClass('btn-group-vertical')) {
				quiz.allButtonGroups.addClass('btn-group-vertical');
			}
		}
	}
};
$(function () {
	quiz.init();
});