var umbracoForms = {
	init: function () {
		$('section.forms:not(.js-forms-done)').each(function () {
			var $section = $(this);

			if ($section.attr('data-scroll-to-submit-message') === 'true') {
				umbracoForms.scrollToSubmitMessage($section);
			}
		});
	},
	scrollToSubmitMessage: function (section) {
		var $submitmessage = section.find('div.umbraco-forms-submitmessage');
		if (!$submitmessage.length) {
			return;
		}

		site.animate(section);
	}
};
$(function () {
	umbracoForms.init();
});