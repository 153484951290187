var accordions = {
	init: function () {
		$('section.accordions div.accordion:not(.js-accordions-done)').each(function () {
			var $accordion = $(this);
			var $cards = $accordion.children('.card');
			var $collapses = $cards.children('.collapse');
			var $titles = $cards.children('.card-header').children('.card-title');
			$titles.each(function () {
				var $title = $(this);
				$title.on('click', function(e) {
					e.preventDefault();
					$titles.removeClass('card-title-expanded');
					var $collapse = $title.closest('.card').children('.collapse').first();
					$collapses.not($collapse).slideUp('slow');
					$collapse.slideToggle('slow', function () {
						if (!$collapse.is(":visible")) {
							return;
						}
						
						$title.addClass('card-title-expanded');
						site.animate($title);
					});
				});
			});
			$accordion.addClass('js-accordions-done');
		});
	}
};
$(function () {
	accordions.init();
	site.doc.ajaxComplete(function () {
		accordions.init(); 
	});
});