var imageActions = {
	isXl: false,
	sections: null,
	groups: [],
	init: function () {
		imageActions.sections = $('section.image-actions');
		imageActions.sections.find('[data-resize-group]:not(.js-image-actions-done)').each(function () {
			var $this = $(this);
			var group = $this.attr('data-resize-group');
			if ($.inArray(group, imageActions.groups) === -1) {
				imageActions.groups.push(group);
			}
			$this.addClass('js-image-actions-done');
		});
		if (imageActions.groups.length > 0) {
			imageActions.isXl = site.win.width() >= 1200;
			imageActions.resizeGroups();
			site.win.resize(function () {
				imageActions.isXl = site.win.width() >= 1200;
				imageActions.resizeGroups();	
			});
		}
	},
	resizeGroups: function() {
		imageActions.groups.forEach(function (group) {
			var elements = imageActions.sections.find('[data-resize-group="' + group + '"]');
			imageActions.setGroupHeight(elements);
		});
	},
	setGroupHeight: function (elements) {
		if (imageActions.isXl) {
			elements.css('height', '');
			var height = 0;
			elements.each(function() {
				var contentHeight = $(this).height();
				if (contentHeight > height) {
					height = contentHeight;
				}
			});
			elements.height(height);
		} else {
			elements.css('height', '');
		}
	}
};
$(function () {
	imageActions.init();
	site.doc.ajaxComplete(function () {
		imageActions.init();
	});
});