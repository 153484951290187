/*
	bit of a hack...

	seems that the boostrap dropdown don't remove the previous active state when changing the active item

	https://stackoverflow.com/questions/34025624/bootstrap-4-dropdowns-only-work-one-times-with-tabs-menu
*/
var bootstrapNavs = {
	init: function () {
		$('.nav-pills .dropdown').on('show.bs.tab', function (e) {
			$(this).children().find('.active').removeClass('active');
		});
	}
};
$(function () {
	bootstrapNavs.init();
	site.doc.ajaxComplete(function () {
		bootstrapNavs.init();
	});
});