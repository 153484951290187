var tables = {
	init: function () {
		//the wysiwyg editor allows you to do some pretty evil things... these are the 'styling' tags we want to remove
		var tagsToRemove = ['style', 'class', 'border', 'width', 'height', 'align', 'cellspacing', 'cellpadding'];

		$('.rich-text table:not(.js-tables-done)').each(function () {
			var $this = $(this);

			var $trs = $this.find('tr');
			var $ths = $this.find('th');
			var tds = $this.find('td');

			//clean up those styles...
			for (var i = 0; i < tagsToRemove.length; i++) {
				$this.removeAttr(tagsToRemove[i]);
				$trs.removeAttr(tagsToRemove[i]);
				$ths.removeAttr(tagsToRemove[i]);
				tds.removeAttr(tagsToRemove[i]);
			}

			//add the corresponding th value as a data attribute for the collapse
			$trs.each(function() {
				$(this).find('td:not([data-th])').each(function (index) {
					$(this).attr('data-th', $($ths.get(index)).text());
				});
			});

			//apply the default bs style
			$this.addClass('table');

			$this.addClass('js-tables-done');
		});
	}
};
$(function () {
	tables.init();
	site.doc.ajaxComplete(function () {
		tables.init();
	});
});